<template>
	<v-app>
		<component :is="layout">
			<router-view></router-view>
		</component>
	</v-app>
</template>

<script>
export default {
	name: 'App',
	data() {
		return {
		}
	},
	computed: {
		layout() {
			return (this.$route.meta.layout || 'default') + '-layout';//manipula layout das páginas
		}
	},
	beforeCreate() {
		if (localStorage.getItem('logado') == null) localStorage.setItem('logado', false);//se não existe, seta
		if (localStorage.getItem('nome_usuario') == null) localStorage.setItem('nome_usuario', '');//se não existe, seta
		if (localStorage.getItem('cargo_usuario') == null) localStorage.setItem('desc_cargo', '');//se não existe, seta
		if (localStorage.getItem('cpf') == null) localStorage.setItem('cpf', '');//se não existe, seta
	},
	mounted() {
		// As duas formas abaixo funcionam bem para pegar qual a rota atual
		// console.log(this.$router.history.current.path);
		// console.log(this.$route);

		if (localStorage.getItem('logado') == 'false' && this.$route.name != 'Login') {
			this.$router.push('/login');
		}
	}
};
</script>
