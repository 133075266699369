import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		meta: {layout: 'default'},
		component: () => { return import('../views/Home.vue') }
	},
	{
		path: '/login',
		name: 'Login',
		meta: {layout: 'login'},
		component: () => { return import('../views/Login.vue') }
	},
	{
		path: '/logout',
		name: 'Logout',
		meta: {layout: 'default'},
		component: () => { return import('../views/Logout.vue') }
	},
	{
		path: '/perfil',
		name: 'Perfil',
		meta: {layout: 'default'},
		component: () => { return import('../views/Perfil.vue') }
	},
	{
		path: '/feedback_de_clientes',
		name: 'Feedback de Clientes',
		meta: {layout: 'default'},
		component: () => { return import('../views/Feedback_de_clientes.vue') }
	},
	{
		path: '/registro_de_vendas',
		name: 'Registro de Vendas',
		meta: {layout: 'default'},
		component: () => { return import('../views/Registro_de_vendas.vue') }
	},
	{
		path: '/administrativo/celulares',
		name: 'Celulares',
		meta: {layout: 'default'},
		component: () => { return import('../views/Celulares.vue') }
	},
	{
		path: '/administrativo/exportacao_administrativa',
		name: 'Exportação Administrativa',
		meta: {layout: 'default'},
		component: () => { return import('../views/ExportacaoAdministrativa.vue') }
	},
	{
		path: '/administrativo/unidades_operacionais',
		name: 'Unidades Operacionais',
		meta: {layout: 'default'},
		component: () => { return import('../views/UnidadesOperacionais.vue') }
	},
	{
		path: '/administrativo/usuarios',
		name: 'Usuários',
		meta: {layout: 'default'},
		component: () => { return import('../views/Usuarios.vue') }
	},
	{
		path: '/administrativo/clientes',
		name: 'Clientes',
		meta: {layout: 'default'},
		component: () => { return import('../views/Clientes.vue') }
	},
	{
		path: '/administrativo/produtos',
		name: 'Produtos',
		meta: {layout: 'default'},
		component: () => { return import('../views/Produtos.vue') }
	},
	{
		path: '/administrativo/produtos_por_cliente',
		name: 'Produtos_valor_por_cliente',
		meta: {layout: 'default'},
		component: () => { return import('../views/Produtos_valor_por_cliente.vue') }
	},
	{
		path: '/administrativo/veiculos',
		name: 'Veiculos',
		meta: {layout: 'default'},
		component: () => { return import('../views/Veiculos.vue') }
	},
	{
		path: '/financeiro/contas_a_pagar',
		name: 'Administrativo de Contas a Pagar',
		meta: {layout: 'default'},
		component: () => { return import('../views/Contas_a_pagar.vue') }
	},
	{
		path: '/administrativo/colaboradores',
		name: 'Administrativo de Colaboradores',
		meta: {layout: 'default'},
		component: () => { return import('../views/Colaboradores.vue') }
	},
	{
		path: '/administrativo/gerencial_vendas',
		name: 'Gerencial de Vendas',
		meta: {layout: 'default'},
		component: () => { return import('../views/Gerencial_vendas.vue') }
	},
	{
		path: '/administrativo/gerencial_placas',
		name: 'Gerencial de Placas',
		meta: {layout: 'default'},
		component: () => { return import('../views/Gerencial_placas.vue') }
	},
	{
		path: '/financeiro/gerencial_contas_a_pagar',
		name: 'Gerencial de Contas a Pagar',
		meta: {layout: 'default'},
		component: () => { return import('../views/Gerencial_contas_a_pagar.vue') }
	},
	{
		path: '/administrativo/meta_contato_clientes',
		name: 'Meta de Contato a Clientes',
		meta: {layout: 'default'},
		component: () => { return import('../views/Meta_contato_clientes.vue') }
	},
	{
		path: '/financeiro/financas_do_cliente',
		name: 'Gerencial de Finanças dos Clientes',
		meta: {layout: 'default'},
		component: () => { return import('../views/Financas_do_cliente.vue') }
	},
	{
		path: '/contato_clientes',
		name: 'Contato a Clientes',
		meta: {layout: 'default'},
		component: () => { return import('../views/Contato_clientes.vue') }
	},
	{
		path: '*',
		name: '404',
		meta: {layout: 'default'},
		component: () => { return import('../views/404.vue') }
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
